import { poiMap } from "./mapper";
import { englishToNepaliNumber } from "nepali-number";
import { format, isAfter, isBefore } from "date-fns";
import { ADToBS } from "bikram-sambat-js";

export const filterObject = (obj) => {
  for (const propName in obj) {
    if (
      obj[propName] === "" ||
      obj[propName] === null ||
      obj[propName] === undefined
    ) {
      delete obj[propName];
    }
  }
  return obj;
};

export const groupBy = (key) => (array) =>
  array.reduce((objByKey, obj) => {
    let value = obj[key];
    objByKey[value] = (objByKey[value] || []).concat(obj);
    return objByKey;
  }, {});

export const getPoiTypes = (data) => {
  let type = `poiType${data.poi_type}`;
  let mapper = poiMap[type];

  let mappedValues = {
    name_nep: data.name_nep || null,
    ward: data.ward ? englishToNepaliNumber(data.ward) : null,
    tole: data.tole || null,
    contact_person: data.contact_person || null,
    contact_number: data.contact_number || null,
    _geolocation: {
      lat: data._geolocation[0]
        ? englishToNepaliNumber(data._geolocation[0])
        : null,
      long: data._geolocation[1]
        ? englishToNepaliNumber(data._geolocation[1])
        : null,
    },
    misc: {},
  };

  if (mapper) {
    for (const key in mapper) {
      let tmpMap = mapper[key];

      if (tmpMap.type === "text") {
        mappedValues.misc[tmpMap.label] = data[key] || null;
      }

      if (tmpMap.type === "conversion") {
        mappedValues.misc[tmpMap.label] = data[key]
          ? englishToNepaliNumber(data[key])
          : null;
      }

      if (tmpMap.type === "list") {
        const search = (arr, val) =>
          arr.find((ele) => {
            if (ele.name === val) {
              return ele;
            }
          });

        mappedValues.misc[tmpMap.label] = data[key]
          ? search(tmpMap.children, data[key]).label || null
          : null;
      }
    }
  }

  return mappedValues;
};

/**
 * Converts english number to formatted nepali number system
 * @param {integer} num
 * @returns string
 */
export const getFormatedNepaliNumber = (num) => {
  let len = num.toString().length;
  let cash = [];
  const formats = [
    "one",
    "tens",
    "hundred",
    "thousand",
    "tenThousands",
    "lakh",
    "tenLakh",
    "crore",
    "tenCrore",
    "arab",
    "tenArab",
    "kharab",
    "tenKharab",
  ];
  const loopedFormats = [
    {
      label: "खरब",
      tensplace: "tenKharab",
      onesplace: "kharab",
    },
    {
      label: "अरब",
      tensplace: "tenArab",
      onesplace: "arab",
    },
    {
      label: "करोड",
      tensplace: "tenCrore",
      onesplace: "crore",
    },
    {
      label: "लाख",
      tensplace: "tenLakh",
      onesplace: "lakh",
    },
    {
      label: "हजार",
      tensplace: "tenThousands",
      onesplace: "thousand",
    },
    {
      label: "सय",
      tensplace: "tenHundred",
      onesplace: "hundred",
    },
    {
      label: "",
      tensplace: "tens",
      onesplace: "one",
    },
  ];

  do {
    let div = Math.pow(10, len - 1);
    let val = Math.floor((num / div) % 10);
    cash[formats[len - 1]] = val;
    len--;
  } while (len > 0);

  let str = "";

  for (const i in loopedFormats) {
    if (cash[loopedFormats[i].tensplace] || cash[loopedFormats[i].onesplace]) {
      let tmp =
        (cash[loopedFormats[i].tensplace] || 0) * 10 +
        cash[loopedFormats[i].onesplace];
      str = str + `${englishToNepaliNumber(tmp)} ${loopedFormats[i].label} `;
    }
  }

  return str || englishToNepaliNumber(0);
};

export const listCategories = (list) => {
  let categories = "";
  let subcat = "";
  categories = list
    .map((cat) => {
      return cat.title_ne;
    })
    .join(" || ");

  subcat = list
    .map((cat) => {
      return cat.subcategory
        .map((sub) => {
          return sub.title_ne;
        })
        .join(" || ");
    })
    .join(" || ");
  return categories + ` (${subcat})`;
};

/**
 * Converts nepali number to english number
 * @param {string} number
 * @returns string
 */
export const getEnglishNumber = (number) => {
  let mapper = {
    "०": "0",
    "१": "1",
    "२": "2",
    "३": "3",
    "४": "4",
    "५": "5",
    "६": "6",
    "७": "7",
    "८": "8",
    "९": "9",
  };

  return number.replace(/[०१२३४५६७८९]/g, function (i) {
    return mapper[i];
  });
};

/**
 *  English date YYYY-MM-DD to date picker format
 * @param {string} date
 */
export const getDatepickerFormat = (date, local = "en") => {
  let monthsMap = [
    "बैशाख",
    "जेठ",
    "असार",
    "सावन",
    "भदौ",
    "असोज",
    "कार्तिक",
    "मंसिर",
    "पौष",
    "माघ",
    "फागुन",
    "चैत",
  ];
  if (local == "en") {
    if (
      isAfter(new Date(date), new Date("2021-03-31")) &&
      isBefore(new Date(date), new Date("2021-04-14"))
    ) {
      date = new Date(date);
      date.setDate(date.getDate() - 1);
      date = format(date, "YYYY-MM-DD");
    }
  }
  let nepali_date = local == "en" ? ADToBS(date) : date;
  let parts = nepali_date.split("-");
  let year = englishToNepaliNumber(parts[0]);
  let month = monthsMap[parseInt(parts[1]) - 1];
  let day = englishToNepaliNumber(parts[2]);
  return `${day} ${month} ${year}`;
};
